export const getIcon = (type: string) => {
    const lut = {
        'other': 'fa-regular fa-mailbox-flag-up',
		'email': 'fa-regular fa-at',
		'phone': 'fa-regular fa-phone',
		'whatsapp': 'fa-brands fa-whatsapp',
        'linkedin': 'fa-brands fa-linkedin',
    }

    return lut[type] ?? lut['other']
}

export const getLinkage = (type: string, value: string) => {
    const lut = {
        'other': null,
		'email': `mailto:${value}`,
		'phone': `tel:${value}`,
		'whatsapp': value,
        'linkedin': value,
    }

    return lut[type] ?? null
}

export const getPhone = (value: string) => {
    if (!value) {
        return null
    }

    return value.split(':')[1] ?? null
}